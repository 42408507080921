<template>
	<div class="pa-2">
		<v-row v-for="(v,k,i) in item" class="mx-0" :key="i">
			<template v-if="k.startsWith('link')">
				<v-col align="center" class="label--text font-weight-bold">
					<v-btn color="primary" :href="v" target="_blank">{{k.split(':')[1]}}</v-btn>
				</v-col>
			</template>
			<template v-else-if="v.toString().startsWith('{')">
				<v-col cols="12" class="label--text paragraph font-weight-bold">{{k}}</v-col>
				<v-col>
					<pre style="white-space: pre-wrap" class="language-javascript paragraph">{{ JSON.stringify(JSON.parse(v), undefined, 4) }}</pre>
				</v-col>
			</template>
			<template v-else>
				<v-col class="paragraph label--text">
					{{ k }}
				</v-col>
				<v-col class="paragraph" cols="8">
					{{ v }}
				</v-col>
			</template>
		</v-row>
	</div>
</template>

<script>
	export default {
		name: "ActionInfos",
		props: ['item']
	}
</script>

<style scoped>

</style>